<template>
  <div class="aboutus" style="cursor: pointer">
    <!-- 头部组件 -->
    <header_public :navindex="5"></header_public>
    <!-- 顶部背景图 -->
    <div class="header">
      <div class="d1">积云教育</div>
      <p class="p1">数字技术培训</p>
      <p class="p2">
        从0到1打造高端数字人才
        <br />
      </p>
    </div>
    <!-- 公司介绍 -->
    <div class="company">
      <p class="p3"></p>
      <p class="p4">
        About Us
        <br />公司介绍
      </p>
    </div>
    <div class="company1">
      <div class="companyword">
        北京优思安科技有限公司是一家面向全球的咨询、IT解决方案与服务提供商。公司成立于
        2011年6月，总部位于北京市海淀区，并在上海、武汉、广州设有分支机构。作为“瞪羚
        企业”和“中华职业教育社-团体社员单位”的一员，公司紧跟技术和业务发展潮流，技术
        实力雄厚、人才结构合理，向企业客户提供高端专业的服务。
        <br />公司旗下积云教育于2016年3月成立。积云教育致力于打造创新创业职业教育实训基地，
        打造学员价值，提高企业价值，创造社会价值。积云教育自创立以来发展速度令人瞩目，作
        为国内职业教育行业优秀单位代表，曾荣获腾讯•回响中国“2023年度综合影响力标杆教
        育集团”和“2023年度口碑影响力职业教育品牌”、“国家高新技术企业认证”、“推动
        中国（教育）行业发展杰出人物”等奖项荣誉，并与CCTV证券资讯频道《领导者说》栏
        目达成战略合作。积云教育始终以服务发展为宗旨，以促进高薪就业为导向，系统设计现代
        数字技术赛道职业教育的体系框架、教育理念和运行机制；推动教育理念创新、模式创新和
        制度创新。由此培养了大批领先的高素质工程师和高端数字人才，为国家和全社会经济发展
        提供强有力的人才支撑。
      </div>
      <img src="../../assets/images/sky.png" alt />
    </div>
    <div class="education">
      <img src="../../assets/images/333.jpg" alt />
      <div class="bg-con"></div>
      <div class="bg-box"></div>
      <div class="educationword">
        <p class="p5">积云教育</p>
        <p class="p6">
          积云教育是一家提供高端数字人才培训和高薪就业整体解决方案的教育集团，始终秉持“人人皆可成才”的育人理念、以“创新教育、成就未来”为使命、以“高就业率、高就业薪资、高对口就业率”为培养目标、坚持“产教融合、校企合一”。集团总部位于北京，现拥有北京、河南等多个线下大校区，累计服务全国35000多名学员
        </p>
        <p class="p7">
          作为由北京市科学技术委员会、中关村科技园区管理委员会评定的“北京市瞪羚企业”和中华职业教育社会员单位，积云教育还与诸多知名高校、教育机构和数千家知名企业合作，共同研发建设教育项目，探索新的教育模式。我们专注于培养年薪20W+的高端数字人才，旗下设有：AI短视频大师班、人工智能、Java大数据、新媒体影视等多个热门产品，为广大学员提供领先的数字技术学习平台和广阔的职业发展平台。
        </p>
        <p class="p7">
          自成立以来，积云教育致力于打造中国创新创业职业教育实训基地，传承技术技能、发扬工匠精神、打造学员价值、提高企业价值、创造社会价值；始终坚持以服务发展为宗旨、以促进高薪就业为导向、系统设计现代数字技术赛道职业教育的体系框架、教育理念和运行机制；推动教育理念创新、模式创新和制度创新。由此培养了大批领先的高素质工程师和高端数字人才，为国家和全社会经济发展提供强有力的人才支撑。
        </p>
      </div>
    </div>
    <!-- 办学理念 -->
    <div class="idea">
      <p>Growth Process</p>
      <h1>办学理念</h1>
      <img style="margin-bottom: 2vw" src="../../assets/images/btzst.png" alt />
    </div>
    <div class="money">
      <div class="money1">
        <p>
          深度融合的
          <span>校企合一</span>
        </p>
        <ul>
          <li>
            <img src="../../assets/images/dgg_03.png" alt />&nbsp;&nbsp;企业合作实习实训
          </li>
          <li>
            <img src="../../assets/images/dgg_03.png" alt />&nbsp;&nbsp;培养目标从企业中来
          </li>
          <li>
            <img src="../../assets/images/dgg_03.png" alt />&nbsp;&nbsp;双师型教师从企业中来
          </li>
          <li>
            <img src="../../assets/images/dgg_03.png" alt />&nbsp;&nbsp;积分制管理从企业中来
          </li>
          <li>
            <img src="../../assets/images/dgg_03.png" alt />&nbsp;&nbsp;KPI绩效考核从企业中来
          </li>
          <li>
            <img src="../../assets/images/dgg_03.png" alt />&nbsp;&nbsp;培养目标的标准从企业中来
          </li>
          <li>
            <img src="../../assets/images/dgg_03.png" alt />&nbsp;&nbsp;实验室、实训项目从企业中来
          </li>
          <li>
            <img src="../../assets/images/dgg_03.png" alt />&nbsp;&nbsp;质量控制方法PDCA循环从企业中来
          </li>
        </ul>
      </div>
      <img class="img1" src="../../assets/images/h4-holder-single-img-2.png" alt />
    </div>
    <!-- 快乐学习 -->
    <div class="happybox">
      <div class="happy">
        <ul class="flex_public">
          <li class="flex_public floating">
            <img src="../../assets/images/sjck_03.png" alt />
            <h5>人人皆可成才</h5>
            <p>
              “人人皆可成才，人人进展其才。”打破学员和教师的认知局限：对人的潜力认知不足影响人对自己的认知，教育的不均衡性影响学员对自己的认知。以“掌握性学习”、“反馈教学原则”和“目标分类理论”为依据，建立突出能力培养的新型职业技术教学模式。
            </p>
          </li>
          <li class="flex_public floating">
            <img src="../../assets/images/rgzna_03.png" alt />
            <h5>快乐学习</h5>
            <p>
              学习的目的总是源于美好的期待或展望学员希望就好业、好发展，回馈父母，成就自己,
              对目标的美好期待会给人当前带来快乐，未来的结果会影响现在的情绪。学习的过程是快乐的。人学习的过程就是不断发现问题，解决问题，不断提升个人的能力过程。
            </p>
          </li>
        </ul>
        <ul class="flex_public">
          <li class="flex_public floating">
            <img src="../../assets/images/jqxx_03.png" alt />
            <h5>自主学习</h5>
            <p>学习自主化是通过讲师的组织和引导，以学习小组为单位自学，引导学员自主讲解有代表性的问题、引导学员自主讲课，这样既提高学员的自学能力，又激发学员的参与意识，获得学习的成就感，还能提升学员的沟通表达能力。</p>
          </li>
          <li class="flex_public floating">
            <img src="../../assets/images/rgzna_03.png" alt />
            <h5>游戏化教学</h5>
            <p>游戏化教学是指教师在教学中将游戏的关键元素引入教学中，如PK、挑战、积分、游戏活动设计等。通过游戏元素的引入，激发学员课堂参与的积极性和学习兴趣。</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 美国cbe教学模式 -->
    <div class="top_text">
      <span>CBE Teaching mode</span>
      <h5>CBE教学模式</h5>
      <img src="../../assets/images/btzst.png" alt />
    </div>
    <div class="cbeimg">
      <img width="100%" src="../../assets/images/CBE.png" alt />
    </div>
    <!-- 发展历程 -->
    <div class="top_text">
      <span>Growth Process</span>
      <h5>发展历程</h5>
      <img src="../../assets/images/btzst.png" alt />
    </div>
    <div class="growth">
      <div class="growth1">
        <ul class="ulOne" @mousemove="move('ulOne')" @mouseleave="levae('ulOne')">
          <li class="li1">2016/</li>
          <li class="li2">积云元年</li>
          <li class="li3">The first year JiYun</li>
          <li class="li4">
            <p>2016年3月1日 北京积云教育正式成立</p>
            <p>2016年7月 北京积云教育教育研究院正式成立</p>
            <p>2016年8月31日，积云的第一批学员顺利毕业！</p>
            <p>
              2016年9月23日
              北京积云教育与CCTV证券资讯频道《领导者说》栏目达成战略合作
            </p>
            <p>2016年10月 在校人数突破3000人</p>
          </li>
        </ul>
        <ul class="ulTwo" @mousemove="moveTow('ulTwo')" @mouseleave="levaeTow('ulTwo')">
          <li class="li1">2017/</li>
          <li class="li2">乘风破浪</li>
          <li class="li3">The rapid rise</li>
          <li class="li4">
            <p>2017年4月29日，北京积云教育荣获“推动中国（教育）行业发展杰出人物”奖！</p>
            <p>2017年7月，十多家权威媒体聚焦报道积云教育爱心助学佳话。</p>
            <p>2017年9月26日，多家权威媒体报道积云教育为软件人才培养献计献策。</p>
            <p>2017年12月27日，积云教育、朝阳师范高等专科学校校企合作顺利签约！</p>
          </li>
        </ul>
        <ul class="ulThree" @mousemove="move('ulThree')" @mouseleave="levae('ulThree')">
          <li class="li1">2018/</li>
          <li class="li2">快速崛起</li>
          <li class="li3">Ride the wind and waves</li>
          <li class="li4">
            <p>2018年1月31日，美国上市公司正保远程教育集团正式投资积云教育，双方达成长久性战略合作伙伴关系。</p>
            <p>2018年6月6日，积云教育集团与华图众成教育签订学历教育战略合作协议。</p>
            <p>2018年6月6日，《中国教育报》教育展台版，整版报道积云教育《创新教育模式，保障学员优质就业》文章，深刻讲述了积云教育践行“人人皆可成才”的育人理念，让学员快乐学习，高效就业！</p>
            <p>2018年12月15日，积云教育荣获HRID2018年中国人才发展论坛“最具竞争力成长型企业奖”。</p>
          </li>
        </ul>
        <ul class="ulFore" @mousemove="moveTow('ulFore')" @mouseleave="levaeTow('ulFore')">
          <li class="li1">2019/</li>
          <li class="li2">分校战略</li>
          <li class="li3">Strategic of branch school</li>
          <li class="li4">
            <p>2019年1月，积云教育郑州校区成立。</p>
            <p>2019年3月1日，积云教育成立三周年。</p>
            <p>2019年6月18日，积云教育与北京快帮科技有限公司正式签约，达成战略合作伙伴关系，双方共建实习实训基地，对接一线企业需求，培养前沿新媒体设计师。</p>
          </li>
        </ul>

        <ul class="ulFive" @mousemove="move('ulFive')" @mouseleave="levae('ulFive')">
          <li class="li1">2020/</li>
          <li class="li2">教学精进</li>
          <li class="li3">Advanced teaching</li>
          <li class="li4">
            <p>积云教育成为“河南电视台战略合作伙伴”</p>
            <p>积云教育被授予“河南省复转军人创业就业培训基地”</p>
            <p>积云教育荣获“2020年度口碑影响力职业教育品牌奖”</p>
            <p>积云教育董事长别林波先生获得“2020年度教育行业领军人物奖”</p>
            <p>积云教育被列入第一批北京市经济和信息化局《北京市高精尖产业技能提升培训机构》目录</p>
          </li>
        </ul>

        <ul class="ulSex" @mousemove="moveTow('ulSex')" @mouseleave="levaeTow('ulSex')">
          <li class="li1">2021/</li>
          <li class="li2">再创辉煌</li>
          <li class="li3">Create more brilliance</li>
          <li class="li4">
            <p>获得基因资本千万级B轮融资</p>
            <p>累计助力2万学员高薪就业，累计招收学员3万+</p>
          </li>
        </ul>

        <ul class="ulSeven" @mousemove="move('ulSeven')" @mouseleave="levae('ulSeven')">
          <li class="li1">2022/</li>
          <li class="li2">持续突破</li>
          <li class="li3">Drive to the basket</li>
          <li class="li4">
            <p>成立集团品牌部、新媒体部和网络营销部，助力集团业务全场景立体化布局发展</p>
            <p>课程迭代更新，“7.0课程体系”全新发布</p>
            <!-- <p>“测试开发火箭班”课程重磅上线</p> -->
          </li>
        </ul>

        <ul class="ulEight" @mousemove="moveTow('ulEight')" @mouseleave="levaeTow('ulEight')">
          <li class="li1">2023/</li>
          <li class="li2">2023 组织精进</li>
          <li class="li3">Organization evolution</li>
          <li class="li4">
            <p>8.0 课程体系全新发布</p>
            <p>荣获腾讯·回响中国“2023年度综合影响力标杆教育集团”和“2023年度口碑影响力职业教育品牌”</p>
            <p>获评“中华职业教育社-团体社员单位”</p>
          </li>
        </ul>

        <ul class="ul2024" style="background-color: transparent;display:none;">
          <!-- <li class="li1">2024/</li>
          <li class="li2">聚力破局</li>
          <li class="li3">Full breakthrough</li>
          <li class="li4">
            <p>9.0课程体系全新发布</p>
            <p>品牌运营升级</p> 
          </li> -->
        </ul>

        <ul class="ul2025" @mousemove="moveTow('ul2025')" @mouseleave="levaeTow('ul2025')">
          <li class="li1">2024/</li>
          <li class="li2">聚力破局</li>
          <li class="li3">Full breakthrough</li>
          <li class="li4">
            <p>9.5课程体系全新发布</p>
            <p>品牌运营升级</p>
          </li>
        </ul>

        <!-- <ul class="ul10" @mousemove="moveTow('ulEight')" @mouseleave="levaeTow('ulEight')">
          <li class="li1">2023/</li>
          <li class="li2">2023 组织精进</li>
          <li class="li3">Organization evolution</li>
          <li class="li4">
            <p>8.0 课程体系全新发布</p>
            <p>设立“长三角就业服务中心”</p>
            <p>荣获腾讯·回响中国“2023年度综合影响力标杆教育集团”和“2023年度口碑影响力职业教育品牌”</p>
            <p>获评“中华职业教育社-团体社员单位”</p>
          </li>
        </ul> -->


      </div>
      <!-- <div class="growth2" id="growth_2020">
        <ul>
          <li class="li1">2022/</li>
          <li class="li2">携手未来</li>
          <li class="li3">Join hands in the future</li>
          <li class="li4">
            “人人皆可成才，人人尽展其才。”美国著名的教育心理学家布鲁姆：“只要提供了适当的前提和现实条件，几乎所有人都能学会一个人在世界上所能学会的东西。”以布鲁姆的“掌握性学习”、“反馈教学原则”和“目标分类理论”为依据，建立突出能力培养的新型职业技术教学模式。打破学员和教师的认知局限：对人的潜力认知不足影响人对自己的认知，教育的不均衡性影响学员对自己的认知。
          </li>
        </ul>
      </div>-->
    </div>
    <!-- 资质荣誉 -->
    <div class="honor">
      <div class="honor1">
        <div class="top_text">
          <span>Qualification honor</span>
          <h5>资质荣誉</h5>
          <img src="../../assets/images/btzst.png" alt />
        </div>
        <div class="jiantou">
          <img src="../../assets/images/jtle_03.png" alt class="honor-prev" />
          <img src="../../assets/images/jtri_03.png" alt class="honor-next" />
        </div>
        <!-- 荣誉证书 -->
        <div class="honorbottom">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="../../assets/images/honor7.jpg" alt />
                <span>北京优思安科技献礼70周年</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor8.png" alt />
                <span>腾讯教育回响中国 2023年度口碑影响力职业教育品牌</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor9.jpg" alt />
                <span>推动中国（教育）行业发展杰出人物</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/yingxiangzg.png" alt />
                <!-- <img src="../../assets/images/honor10.jpg" alt /> -->
                <!-- <span>CCTV《领导者说》合作伙伴</span> -->
                <span>腾讯教育回响中国 2023年度综合影响力标杆教育集团</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor11.jpg" alt />
                <span>中国软件行业协会</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor12.jpg" alt />
                <span>联想大数据技术合作培训中心</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor13.jpg" alt />
                <span>中关村人历资源总监联盟</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor14.jpg" alt />
                <span>中关村移动互联网产业联盟</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor15.jpg" alt />
                <span>中国诚信服务示范单位</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor16.jpg" alt />
                <span>中国高新区协会软件园分会</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor17.jpg" alt />
                <span>中国软件与信息服务外包联盟</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor18.jpg" alt />
                <span>中国云计算产业基地联盟</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor19.png" alt />
                <span>中国中小商业企业协会人历资本分会-常委委员单位</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor20.png" alt />
                <span>中国中小商业企业协会人历资本分会-发起单位</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor21.jpg" alt />
                <span>最具竞争力成长型企业奖</span>
              </div>
              <div class="swiper-slide">
                <img src="../../assets/images/honor22.jpg" alt />
                <span>最具竞争力成长型企业证书</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 教学环境 -->
    <div class="top_text">
      <span>School Style</span>
      <h5>校园风采</h5>
      <img src="../../assets/images/btzst.png" alt />
    </div>
    <div class="environment">
      <!-- <ul class="ul1">
        <li class="l1">校园一角</li>
        <li class="l2">
          桃红柳绿的春天，花繁叶茂的夏天，
          <br />枫红菊香的秋天，松青雪白的冬天…… 随手一拍，都是风景
        </li>
      </ul>-->
      <ul>
        <li class="env-li">
          <img src="../../assets/images/about/school.jpg" class="imgScale" alt />
        </li>
      </ul>
      <ul>
        <li class="env-li">
          <img src="../../assets/images/about/hj2.png" class="imgScale" alt />
        </li>
      </ul>
      <ul>
        <li class="env-li">
          <img src="../../assets/images/about/zhibo.jpg" class="imgScale" alt />
        </li>
      </ul>
      <ul>
        <li class="env-li">
          <img src="../../assets/images/about/class.jpg" class="imgScale" alt />
        </li>
      </ul>
      <ul>
        <li class="env-li">
          <img src="../../assets/images/about/jiaoshi.jpg" class="imgScale" alt />
        </li>
      </ul>
      <ul>
        <li class="env-li">
          <img src="../../assets/images/about/hj4.png" class="imgScale" alt />
        </li>
      </ul>

      <!-- <ul class="ul2">
        <li class="l1">教室一角</li>
        <li class="l2">
          环境塑造人，好的学习环境可以给你
          <br />带来不一样的体验和感悟
        </li>
      </ul>-->
    </div>
    <div class="enterprise width_100" style="height: 50px"></div>

    <!-- 底部 -->
    <bottom_public></bottom_public>
  </div>
</template>
<script>
  import Swiper from 'swiper/js/swiper.min.js';
  import 'swiper/css/swiper.min.css';
  import bottom_public from '../../components/bottom_public/bottom_public';
  import header_public from '../../components/header_public/header_public';
  import Enterprise from '../../components/enterprise/enterprise';

  const axios = require('axios').default;
  export default {
    metaInfo: {
      title: '关于我们-[积云教育]',
      meta: [
        {
          name: 'description',
          content:
            '北京优思安科技有限公司是一家面向全球的咨询、IT解决方案与服务提供商。公司成立于2011年6月，总部位于北京市海淀区，并在上海、武汉、广州设有分支机构。公司紧跟技术和业务发展潮流，技术实力雄厚、人才结构合理，向企业客户提供高端专业的服务。',
        },
        {
          name: 'keywords',
          content: '关于我们',
        },
      ],
    },
    created() {
      this.getschoolScience(); //校园环境
    },
    data() {
      return {
        schoolScience: {},
        swiper: {},
      };
    },
    methods: {
      getschoolScience() {
        axios
          .get('/api/block/getItemsByName?name=' + encodeURIComponent('校园环境'))
          .then((res) => {
            this.schoolScience = res.data.data; //校园环境列表数据
          });
      },
      move(res) {
        let x = document.getElementsByClassName(res);
        let y = x[0].nextSibling;
        let z = x[0].childNodes;
        x[0].style.backgroundColor = '#882e3c';
        x[0].style.height = '31.4vw';
        // x[0].style.display = "block";
        x[0].style.transition = 'all 1s ease 0s';
        y.style.display = 'none';
        for (let i = 0; i < z.length; i++) {
          z[i].style.color = '#ffffff';
          z[3].style.display = 'block';
          z[3].style.margin = '1vw 1.5vw 0';
        }
        document.getElementById('growth_2020').classList.add('no-active');
      },
      moveTow(res) {
        let x = document.getElementsByClassName(res);
        let y = x[0].previousSibling;
        let z = x[0].childNodes;
        x[0].style.backgroundColor = '#882e3c';
        x[0].style.height = '31.4vw';
        x[0].style.transition = 'all 1s ease 0s';
        // x[0].style.display = "block";
        y.style.display = 'none';
        for (let i = 0; i < z.length; i++) {
          z[i].style.color = '#ffffff';
          z[3].style.display = 'block';
          z[3].style.margin = '1vw 1.5vw 0';
        }
        document.getElementById('growth_2020').classList.add('no-active');
      },
      levae(res) {
        let x = document.getElementsByClassName(res);
        let y = x[0].nextSibling;
        let z = x[0].childNodes;
        x[0].style.backgroundColor = '#f8f8f8';
        x[0].style.height = '15.6vw';
        x[0].style.display = 'flex';
        x[0].style.transition = '';
        y.style.display = 'flex';
        for (let i = 0; i < z.length; i++) {
          z[0].style.color = '#de2945';
          z[1].style.color = '#333333';
          z[2].style.color = '#888888';
          z[3].style.display = 'none';
        }
        document.getElementById('growth_2020').classList.remove('no-active');
      },
      levaeTow(res) {
        let x = document.getElementsByClassName(res);
        let y = x[0].previousSibling;
        let z = x[0].childNodes;
        x[0].style.backgroundColor = '#f8f8f8';
        x[0].style.height = '15.6vw';
        x[0].style.transition = '';
        x[0].style.display = 'flex';
        y.style.display = 'flex';
        for (let i = 0; i < z.length; i++) {
          z[0].style.color = '#de2945';
          z[1].style.color = '#333333';
          z[2].style.color = '#888888';
          z[3].style.display = 'none';
        }
        document.getElementById('growth_2020').classList.remove('no-active');
      },
    },
    mounted() {
      new Swiper('.swiper-container', {
        slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 30,
        navigation: {
          nextEl: '.honor-next',
          prevEl: '.honor-prev',
        },
      });
    },
    components: {
      Enterprise,
      bottom_public,
      header_public,
    },
  };
</script>
<style lang="scss" scoped>
  * {
    padding: 0;
  }

  .tips {
    position: relative;
    bottom: -4.2vw;
  }

  .imgScale:hover {
    cursor: pointer;
    transform: scale(1.1);
    width: 100%;
    height: 100%;
    transition: all 2s;
    position: relative;
  }

  .floating:hover {
    transform: translateY(-20px);
    transition: all 0.5s;
    cursor: pointer;
  }

  .d1::after {
    background-color: transparent !important;
  }

  .d1::before {
    background-color: transparent !important;
  }

  .header {
    width: 100%;
    height: 61vw;
    background-image: url("../../assets/images/aboutbanner.png");
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
  }

  .header .d1 {
    width: auto;
    height: 4vw;
    line-height: 4vw;
    position: absolute;
    top: 16vw;
    left: 18.7vw;
    color: #fff;
    font-size: 7.5rem;
    font-family: PingFangSC-Medium;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }

  .header .p1 {
    width: auto;
    height: 6vw;
    position: absolute;
    top: 23.3vw;
    left: 18.5vw;
    color: #fff;
    font-family: PingFangSC-Medium;
    font-size: 7.5rem;
    text-align: left;
  }

  .header .p2 {
    width: 51.7vw;
    height: 8.3vw;
    position: absolute;
    top: 31.6vw;
    left: 19vw;
    color: #fff;
    font-size: 5rem;
    font-family: PingFang SC;
    color: rgba(255, 255, 255, 1);
    opacity: 0.7;
    text-align: left;
    font-weight: 300;
    line-height: 5.6rem;
    text-align: left;
  }

  .company {
    width: 40vw;
    height: 4vw;
    display: flex;
    align-items: center;
  }

  .company .p3 {
    width: 16vw;
    height: 0.2vw;
    background: rgba(54, 72, 215, 1);
    margin-top: 4vw;
  }

  .company .p4 {
    width: auto;
    height: 3.9vw;
    font-size: 3.1rem;
    font-family: PingFang SC;
    font-weight: 300;
    color: rgba(54, 112, 216, 1);
    line-height: 4.1vw;
    margin-left: 1.5vw;
  }

  .company1 {
    width: 62.5vw;
    height: 42.6vw;
    margin: 0 auto;
    margin-top: 5vw;
    display: flex;
    justify-content: space-between;
  }

  .companyword {
    width: 35.2vw;
    height: 40.4vw;
    font-size: 1.2rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 2.6rem;
    text-align: left;
  }

  .company1 img {
    width: 34vw;
    height: 41vw;
  }

  .education {
    margin-top: 5vw;
    background-image: url("../../assets/images/garybg.png");
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    width: 100%;
    height: 67vw;
    position: relative;
  }

  .education .bg-con {
    position: absolute;
    width: 70vw;
    height: 55%;
    padding: 100px;
    // border: 1px solid #0e0a4c;
    background-color: #3976d3;
    right: 0;
  }

  .education .bg-box {
    position: absolute;
    width: 32vw;
    height: 33vw;
    // border: 5px solid #3976D3;
    left: 0;
    top: 1.6vw;
    border-left: none;
  }

  .education img {
    width: 50vw;
    height: 30vw;
    position: absolute;
    z-index: 2;
    top: 7rem;
    left: 0;
  }

  .educationword {
    width: 35vw;
    // height: 25vw;
    font-family: PingFang SC;
    position: absolute;
    top: 5vw;
    left: 53vw;
    z-index: 1;
  }

  .educationword .p5 {
    font-size: 2.1rem;
    color: #fff;
  }

  .educationword .p6 {
    width: 35vw;
    height: auto;
    margin-top: 1vw;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.1vw;
    color: #fff;
    text-indent: 2.6rem;
    text-align: justify;
  }

  .educationword .p7 {
    width: 35vw;
    height: auto;
    text-indent: 2.6rem;
    text-align: justify;
    margin-top: 1vw;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.1vw;
    color: #fff;
  }

  // 办学理念
  .idea p {
    font-size: 0.8rem;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: rgba(109, 109, 109, 1);
  }

  .idea h1 {
    font-size: 1.7vw;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
    font-family: PingFang SC;
    font-weight: 600;
    color: rgba(28, 26, 91, 1);
  }

  .money {
    width: 62.5vw;
    height: 26.77vw;
    margin: 0 auto;
    display: flex;
  }

  .money .img1 {
    width: 42.2vw;
    height: 26.7vw;
  }

  .money1 {
    // background-color: yellow;
    width: 26vw;
    height: 25vw;
    text-align: left;
  }

  .money1 p {
    font-size: 2.7rem;
    color: black;
  }

  .money1 p span {
    color: red;
  }

  .money1 ul {
    // background-color: red;
    width: 14.16vw;
    height: 14.8vw;
    line-height: 2vw;
    font-size: 0.94rem;
    margin-top: 1vw;
  }

  // 快乐办学
  .happybox {
    background-image: url("../../assets/images/garybg.png");
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    width: 100%;
    height: 67vw;
  }

  .flex_public {
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
  }

  .happy {
    width: 62.5vw;
    margin: 0 auto;
  }

  .happy ul {
    // background-color:red;
    // height: 27.5vw;
    margin-bottom: 70px;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .happy ul li {
    // background-color: #fff;
    width: 29.08vw;
    height: 27vw;
    background-color: #fff;
    flex-flow: column nowrap;
    align-items: center;
    box-shadow: 0px 12px 26px 1px rgba(206, 212, 239, 0.63);
    border-radius: 36px 0px 36px 0px;
  }

  .happy ul li img {
    width: 4.166vw;
    height: 3.437vw;
    margin-top: 3vw;
  }

  .happy ul li h5 {
    font-size: 1.43rem;
    font-weight: 800;
    color: rgba(51, 61, 88, 1);
    margin: 1.5vw 0;
  }

  .happy ul li p {
    padding: 0 20px;
    width: 24.6vw;
    /*height: 7.65vw;*/
    font-size: 1.1rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 2rem;
    text-align: left;
  }

  // 美国cbe
  .top_text span {
    font-size: 0.562rem;
    font-family: PingFang;
    font-weight: bold;
    color: rgba(114, 114, 114, 1);
  }

  .top_text h5 {
    font-size: 2rem;
    font-family: PingFang SC;
    font-weight: 600;
    color: rgba(28, 26, 91, 1);
    margin: 0.8rem auto;
  }

  .top_text {
    margin: 6rem 0 4.25rem 0;
  }

  .growth {
    width: 85.3vw;
    height: 31.5vw;
    margin: 0 auto;
    //    background-color: blue;
    // border: 1px solid red;
    display: flex;
    justify-content: center;
  }

  .growth1 {
    position: relative;
    width: 85.3vw;
    height: 31.5vw;
    display: flex;
    flex-flow: column wrap;
    // border: 1px solid blue;
  }

  .growth1 ul {
    position: absolute;
    // width: 20.9vw;
    width: 16.7vw;
    height: 15.6vw;
    margin-top: 0.1vw;
    background: rgba(248, 248, 248, 1);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .growth1 .ulTwo {
    bottom: 0;
  }

  .growth1 .ulThree {
    left: 17.2vw;
  }

  .growth1 .ulFore {
    left: 17.2vw;
    bottom: 0;
  }

  .ulFive {
    left: 34.4vw;
  }

  .ulSex {
    left: 34.4vw;
    bottom: 0;
  }

  .ulSeven {
    left: 51.6vw;
  }

  .ulEight {
    left: 51.6vw;
    bottom: 0;
  }

  .ul2024 {
    left: 68.8vw;
    // bottom: 0;
  }

  .ul2025 {
    left: 68.8vw;
    bottom: 0;
    height: 31.4vw !important;
  }

  .growth1 ul li {
    text-align: left;
    margin-left: 1.5vw;
  }

  .growth1 ul .li1 {
    font-size: 3.1rem;
    font-family: PingFang SC;
    font-weight: 300;
    color: rgba(222, 41, 69, 1);
    /*line-height: 4.16vw;*/
    /*text-indent: 1em;*/
    /*margin-top: 2vw;*/
  }

  .growth1 ul .li2 {
    margin: 0.5vw 0 0.5vw 1.5vw;
    font-size: 2.1rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
  }

  .growth1 ul .li3 {
    font-size: 1.25rem;
    font-family: PingFang SC;
    font-weight: 300;
    color: rgba(136, 136, 136, 1);
  }

  .growth1 ul .li4 {
    font-size: 1rem;
    font-family: PingFang SC;
    font-weight: 300;
    color: #000000;
    display: none;
    line-height: 30px;
  }

  .growth2 {
    background: rgba(136, 46, 60, 1);
    width: 19.58vw;
    height: 100%;
  }

  .growth2.no-active {
    background: rgba(248, 248, 248, 1);
  }

  .growth2 ul {
    width: 85%;
    height: 80%;
    margin-left: 1.5vw;
    margin-top: 1vw;
  }

  .growth2 ul .li1 {
    width: 100%;
    height: 2.44vw;
    font-size: 3.1rem;
    font-family: PingFang SC;
    font-weight: 300;
    color: #fff;
    line-height: 4.16vw;
    text-align: left;
  }

  .growth2.no-active ul .li1 {
    color: #de2945;
  }

  .growth2 ul .li2 {
    width: 100%;
    height: 1.7vw;
    font-size: 2.1rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #fff;
    line-height: 4.16vw;
    margin-top: 1vw;
    text-align: left;
  }

  .growth2.no-active ul .li2 {
    color: #333333;
  }

  .growth2 ul .li3 {
    width: 100%;
    height: 0.83vw;
    font-size: 1.25rem;
    font-family: PingFang SC;
    font-weight: 300;
    color: #fff;
    line-height: 4.16vw;
    margin-top: 1vw;
    text-align: left;
  }

  .growth2.no-active ul .li3,
  .growth2.no-active ul .li4 {
    color: #888888;
  }

  .growth2 ul .li4 {
    width: 100%;
    height: 16.45vw;
    font-size: 1rem;
    font-family: PingFang SC;
    font-weight: 300;
    color: rgba(255, 255, 255, 1);
    line-height: 1.56vw;
    margin-top: 3vw;
    text-align: left;
  }

  .honor {
    width: 100%;
    height: 58vw;
    background-image: url("../../assets/images/garybg.png");
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .honor1 {
    width: 62.5vw;
    height: 80%;
    margin: 0 auto;
  }

  .honor .jiantou {
    text-align: right;
  }

  .jiantou img {
    width: 2.6vw;
    height: 2.6vw;
    margin-right: 1vw;
    outline: none;
  }

  // 荣誉证书
  .honorbottom {
    width: 62.5vw;
    height: 33vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    // background-color: #3648d7;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    height: 15.625vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 0.5vw !important;
  }

  .swiper-slide img {
    width: 100%;
    height: 80%;
  }

  .swiper-slide span {
    display: inline-block;
    text-align: left;
    font-size: 1.325rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    margin-top: 0.6vw;
  }

  // 教学环境
  .environment {
    width: 62.5vw;
    height: auto;
    margin: 0 auto;
    // background-color: #3648D7;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .environment ul {
    width: 33%;
    height: 48%;
    margin-top: 2vw;
    // background-color: #3648d7;
  }

  .environment ul img {
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 8px 0px;
  }

  .environment ul .env-li {
    width: 20.3vw;
    height: 20.3vw;
    overflow: hidden;
  }

  .environment ul li {
    width: 70%;
    // height: 20%;
    // background-color: #3648D7;
    font-size: 1rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    // line-height:31.52vw;
  }

  .environment ul .l1 {
    height: 4vw;
  }

  .environment ul .l2 {
    height: 5vw;
  }

  .enterprise_con {
    width: 62.5vw;
    margin: 0 auto;
  }

  .enterprise_con ul {
    height: 2.916vw;
    margin-bottom: 20px;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .enterprise_con ul li {
    width: 7.916vw;
    height: 100%;
    border: 1px solid rgba(191, 191, 191, 1);
  }

  .enterprise_con ul li img {
    width: 100%;
    height: 100%;
  }

  .cbeimg {
    width: 62.5vw;
    margin: 0 auto;
  }

  .ul1 {
    text-align: left;
  }

  .ul1 .l1 {
    margin-top: 4vw;
    font-size: 1.87rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(32, 32, 32, 1);
  }

  .ul1 .l2 {
    font-size: 1rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 1.56vw;
  }

  .ul2 .l1 {
    margin-top: 6vw;
    font-size: 1.87rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(32, 32, 32, 1);
    text-align: left;
    margin-left: 2vw;
  }

  .ul2 .l2 {
    font-size: 1rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 1.56vw;
    text-align: left;
    margin-left: 2vw;
  }
</style>